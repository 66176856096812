<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { ArrowRightOnRectangleIcon } from '@heroicons/vue/24/solid'
import {
  HeartIcon,
  InformationCircleIcon,
  UserIcon,
} from '@heroicons/vue/24/solid'
import { useCurrentUser } from 'vuefire'

import { useUserStore } from '@/stores/user'
const currentUser = useCurrentUser()
const authLoaded = computed(() => {
  // null means "not authenticated", undefined means "not loaded"
  // see https://vuefire.vuejs.org/api/#usecurrentuser
  return currentUser.value !== undefined
})
const userStore = useUserStore()

const { logout } = userStore

const mobileMenuOpen = ref(false)

const navigation = [
  {
    name: 'Min side',
    routePath: '/me',
    guard: true,
    icon: HeartIcon,
  },
  {
    name: 'Indstillinger',
    routePath: '/account',
    guard: true,
    icon: UserIcon,
  },
  {
    name: 'Om Meddi',
    routePath: '/about',
    guard: true,
    icon: InformationCircleIcon,
  },
]

const tab = ref('/')
const router = useRouter()

const isLoginRoute = computed(() => {
  // null means "not authenticated", undefined means "not loaded"
  // see https://vuefire.vuejs.org/api/#usecurrentuser
  return router.currentRoute.value.path === '/login'
})

watch(tab, async (nv) => {
  if (nv && nv !== router.currentRoute.value.path) {
    router.push(nv)
  }
})

watch(router.currentRoute, async (nv) => {
  if (nv.path && nv.path !== tab.value) {
    tab.value = nv.path
  }
})

const handleLogout = async () => {
  await logout()
  router.push('/')
  mobileMenuOpen.value = false
}
</script>

<template>
  <div class="min-h-full">
    <!-- Header -->
    <header class="absolute inset-x-0 top-0 z-40">
      <nav
        class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <!-- Logo and name -->
        <div class="flex lg:flex-1">
          <RouterLink class="flex flex-shrink-0 items-center" to="/">
            <img class="h-8 w-auto" src="@/assets/logo.png" alt="Meddi logo" />
            <h1 class="ml-3 text-xl font-normal">MEDDI</h1>
          </RouterLink>
        </div>
        <!-- Burger menu toggle-->
        <div v-if="currentUser && authLoaded" class="flex lg:hidden">
          <button
            type="button"
            class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            @click="mobileMenuOpen = true"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <!-- Desktop menu -->
        <div v-if="currentUser" class="hidden lg:flex lg:gap-x-12">
          <a
            v-for="item in navigation"
            :key="item.name"
            :href="item.routePath"
            class="text-sm font-semibold leading-6 text-gray-800 hover:text-gray-600"
          >
            {{ item.name }}
          </a>
        </div>
        <div v-if="!currentUser && authLoaded" class="flex flex-1 justify-end">
          <!-- <RouterLink
            v-if="!isLoginRoute"
            to="/login"
            class="text-sm font-semibold leading-6 text-gray-800 hover:text-gray-600"
          >
            Log ind
            <span aria-hidden="true">&rarr;</span>
          </RouterLink> -->
          <!-- <RouterLink
            v-else
            to="/register"
            class="text-sm font-semibold leading-6 text-gray-800 hover:text-gray-600"
          >
            Opret dig
            <span aria-hidden="true">&rarr;</span>
          </RouterLink> -->
        </div>
        <div
          v-else-if="currentUser"
          class="hidden lg:flex lg:flex-1 lg:justify-end"
        >
          <a
            @click="handleLogout"
            href="#"
            class="text-sm font-semibold leading-6 text-gray-800 hover:text-gray-600"
          >
            Log ud
            <ArrowRightOnRectangleIcon
              class="h-4 w-4 inline align-text-bottom"
              aria-hidden="true"
            />
          </a>
        </div>
      </nav>

      <!-- Mobile menu -->
      <Dialog
        as="div"
        class="lg:hidden"
        @close="mobileMenuOpen = false"
        :open="mobileMenuOpen"
      >
        <div class="fixed inset-0 z-40" />
        <DialogPanel
          class="fixed inset-y-0 right-0 z-40 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
        >
          <div class="flex items-center justify-between">
            <!-- Logo and name -->
            <RouterLink
              @click="mobileMenuOpen = false"
              class="flex flex-shrink-0 items-center"
              to="/"
            >
              <img
                class="h-8 w-auto"
                src="@/assets/logo.png"
                alt="Meddi logo"
              />
              <h1 class="ml-3 text-xl font-normal">MEDDI</h1>
            </RouterLink>
            <button
              type="button"
              class="-m-2.5 rounded-md p-2.5 text-gray-700"
              @click="mobileMenuOpen = false"
            >
              <span class="sr-only">Close menu</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="space-y-2 py-6">
                <a
                  v-for="item in navigation"
                  :key="item.name"
                  :href="item.routePath"
                  class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-800 hover:bg-gray-50"
                  @click="mobileMenuOpen = false"
                >
                  {{ item.name }}
                </a>
              </div>
              <div v-if="currentUser && authLoaded" class="py-6">
                <a
                  @click="handleLogout"
                  href="#"
                  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-800 hover:bg-gray-50"
                >
                  Log ud
                </a>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>

    <main class="min-h-screen w-full relative pb-48">
      <slot></slot>
    </main>
  </div>
</template>

<style scoped></style>
