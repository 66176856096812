import { createRouter, createWebHistory } from 'vue-router'
import { getCurrentUser } from 'vuefire'
import { useAppStore } from '@/stores/app'

const router = createRouter({
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { top: 0 }
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'front',
      component: () => import('@/views/FrontView.vue'),
      meta: {
        requiresAuth: false,
        onlyUnAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: '/landing',
      name: 'landing',
      redirect: '/',
      // component: () => import('@/views/LandingView.vue'),
      meta: {
        requiresAuth: false,
        onlyUnAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: '/login/:redirectPath?',
      name: 'login',
      redirect: '/',
      // component: () => import('@/views/LoginView.vue'),
      // props: true,
      meta: {
        requiresAuth: false,
        onlyUnAuth: true,
        requiresAdmin: false,
      },
    },
    {
      path: '/register',
      name: 'register',
      redirect: '/',
      // component: () => import('@/views/RegisterView.vue'),
      meta: {
        requiresAuth: false,
        onlyUnAuth: true,
        requiresAdmin: false,
      },
    },
    {
      path: '/reset',
      name: 'reset',
      redirect: '/',
      // component: () => import('@/views/ResetView.vue'),
      meta: {
        requiresAuth: false,
        onlyUnAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/AboutView.vue'),
      meta: {
        requiresAuth: false,
        onlyUnAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/AccountView.vue'),
      meta: {
        requiresAuth: true,
        onlyUnAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: '/me',
      name: 'me',
      component: () => import('@/views/MeView.vue'),
      meta: {
        requiresAuth: true,
        onlyUnAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: '/products/:productId',
      props: true,
      name: 'product',
      component: () => import('@/views/ProductView.vue'),
      meta: {
        requiresAuth: true,
        onlyUnAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: '/admin',
      name: 'admin',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('@/views/AdminView.vue'),
      meta: {
        requiresAuth: true,
        onlyUnAuth: false,
        requiresAdmin: true,
      },
    },
    {
      path: '/confirm',
      props: true,
      name: 'confirm',
      component: () => import('@/views/ConfirmView.vue'),
      meta: {
        requiresAuth: false,
        onlyUnAuth: false,
        requiresAdmin: false,
      },
    },
  ],
})

router.beforeEach(async (to) => {
  // Only do checks if route has restrictions
  if (to.meta.requiresAuth || to.meta.onlyUnAuth || to.meta.requiresAdmin) {
    const store = useAppStore()
    const currentUser = await store.withLoading(getCurrentUser())
    if (currentUser) {
      if (to.meta.onlyUnAuth) {
        return { name: 'me' }
      }
      if (to.meta.requiresAdmin) {
        const isAdmin = !!currentUser?.email?.endsWith('@meddi.dk')
        if (!isAdmin) {
          return { name: 'me' }
        }
      }
    } else if (to.meta.requiresAuth) {
      return { name: 'login', params: { redirectPath: to.path } }
    }
  }

  // Default is allowing access
  return true
})

export default router
